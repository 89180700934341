<template>
  <div class="wrap site__main" role="document">
    <div class="content">
      <div id="configurator" class="main_page_wrapper">
        <div class="container mt-10">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div>
                <div class="form-group">
                  <input
                    class="form-control no-border"
                    ref="email"
                    required
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control no-border"
                    ref="password"
                    required
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
              </div>

              <button
                class="btn btn-primary btn-block"
                @click.prevent="authenticate"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      allMachineList: []
    };
  },
  name: "LoginView",
  created() {
    console.warn("LOGIN CREATE");
  },
  methods: {
    ...mapActions(["LOGIN"]),
    async authenticate() {

      const email = this.$refs.email.value;
      const password = this.$refs.password.value;

      if (email.length !== 0 && password.length !== 0) {
        await this.$store
          .dispatch("LOGIN", {
            email: email,
            password: password
          })
          .then(() => {

            router.go(-1);
          })
          .catch(error => {
            console.log("LOGIN ERROR");
            console.log(error);
            router.push("/");
          });
      }

      // router.push("/");
    }
  }
};
</script>
